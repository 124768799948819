import { StyleSheet } from 'react-native';
export const styles = StyleSheet.create({
    indicator: {
        bottom: 0,
        flexDirection: 'row',
        justifyContent: 'center',
        left: 0,
        position: 'absolute',
        right: 0,
    },
});
