export const DEFAULT_CAROUSEL_COLOR_ACTIVE = '#a1a1a1';
export const DEFAULT_CAROUSEL_COLOR_INACTIVE = '#d8d8d8';
export const DEFAULT_CAROUSEL_INDICATOR_SIZE = 12;
const DEFAULT_CAROUSEL_INDICATOR_STYLE = {
    borderRadius: DEFAULT_CAROUSEL_INDICATOR_SIZE / 2,
    height: DEFAULT_CAROUSEL_INDICATOR_SIZE,
    margin: DEFAULT_CAROUSEL_INDICATOR_SIZE / 3,
    width: DEFAULT_CAROUSEL_INDICATOR_SIZE,
};
export const DEFAULT_CAROUSEL_INDICATOR_ACTIVE_STYLE = {
    ...DEFAULT_CAROUSEL_INDICATOR_STYLE,
    backgroundColor: DEFAULT_CAROUSEL_COLOR_ACTIVE,
};
export const DEFAULT_CAROUSEL_INDICATOR_INACTIVE_STYLE = {
    ...DEFAULT_CAROUSEL_INDICATOR_STYLE,
    backgroundColor: DEFAULT_CAROUSEL_COLOR_INACTIVE,
};
export const VIEWABILITY_CONFIG = { itemVisiblePercentThreshold: 60 };
